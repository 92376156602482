import { cloneElement, useState, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonProps } from 'types/section';
import ButtonComponent from 'components/button/Button';
import MonitorButton from 'components/MonitorButton';

// material-ui
import ExploreIcon from '@mui/icons-material/Explore';
import ArticleIcon from '@mui/icons-material/Article';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useTheme } from '@mui/material/styles';
import {
    AppBar as MuiAppBar,
    Box,
    Container,
    Drawer,
    IconButton,
    Link,
    List,
    Stack,
    Toolbar,
    Menu,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    useScrollTrigger,
    // Typography,
    Collapse
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
// import LightModeIcon from '@mui/icons-material/LightMode';
// import DarkModeIcon from '@mui/icons-material/DarkMode';

// project imports

// assets
import LogoSection from '../LogoSection';
import ThemeModeLayout from 'layout/Customization/ThemeMode';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';

// elevation scroll
interface ElevationScrollProps {
    children: ReactElement;
    window?: Window | Node;
}

function ElevationScroll({ children, window }: ElevationScrollProps) {
    const theme = useTheme();

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window!
    });

    return cloneElement(children, {
        elevation: trigger ? 1 : 0,
        width: '100vw',
        style: {
            backgroundColor: theme.palette.mode === 'dark' && trigger ? theme.palette.dark[800] : theme.palette.background.default,
            color: theme.palette.text.dark
        }
    });
}

// ==============================|| MINIMAL LAYOUT APP BAR ||============================== //

const AppBar = ({ ...others }) => {
    const [drawerToggle, setDrawerToggle] = useState<boolean>(false);
    const navigate = useNavigate();

    const drawerToggler = (open: boolean) => (event: any) => {
        if (event.type! === 'keydown' && (event.key! === 'Tab' || event.key! === 'Shift')) {
            return;
        }
        setDrawerToggle(open);
    };

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openProducts, setOpenProducts] = useState<boolean>(false);
    const [openDocs, setOpenDocs] = useState<boolean>(false);
    const [openPricing, setOpenPricing] = useState<boolean>(false);
    const [anchorElDocs, setAnchorElDocs] = useState<null | HTMLElement>(null);
    const [anchorElPricing, setAnchorElPricing] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePricingClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElPricing(event.currentTarget);
    };

    const handleDocsClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElDocs(event.currentTarget);
    };

    const handleOpenProductsClick = () => {
        setOpenProducts(!openProducts);
    };

    const handleOpenDocsClick = () => {
        setOpenDocs(!openDocs);
    };

    const handleOpenPricing = () => {
        setOpenPricing(!openPricing);
    };

    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(null);
    };

    const handleCloseDocs = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElDocs(null);
    };

    const handleClosePricing = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElPricing(null);
    };

    const handleMenuClick = (link: string) => {
        window.open(link, '_blank');
    };

    const handlePriceClick = (path: string) => {
        navigate(path);
    };

    const buttonGo: ButtonProps = {
        name: 'Go',
        link: 'https://ai.x-or.cloud/',
        isNavigate: false
    };

    return (
        <ElevationScroll {...others}>
            <MuiAppBar>
                <Container>
                    <Toolbar sx={{ px: `0 !important`, display: 'flex', justifyContent: 'space-between' }}>
                        {/* <Typography component="div" sx={{textAlign: 'left' }}> */}
                        <LogoSection />
                        {/* </Typography> */}
                        <Stack
                            direction="row"
                            alignItems={'center'}
                            sx={{ display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' } }}
                            gap={3}
                        >
                            <Link
                                color="inherit"
                                component="button"
                                onClick={handleClick}
                                sx={{
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    textDecoration: 'none',
                                    position: 'relative',
                                    cursor: 'pointer',
                                    '&::before': {
                                        content: '""',
                                        position: 'absolute',
                                        left: 0,
                                        bottom: 0,
                                        width: 0,
                                        height: '2px',
                                        backgroundColor: 'primary.main',
                                        transition: 'width 1s cubic-bezier(0.25, 1, 0.5, 1)'
                                    },
                                    '&:hover::before': {
                                        width: '100%'
                                    }
                                }}
                            >
                                <Stack direction={'row'} alignItems="center" gap={1}>
                                    Products
                                    {anchorEl ? <ExpandLessIcon fontSize="medium" /> : <ExpandMoreIcon fontSize="medium" />}
                                </Stack>
                            </Link>
                            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                                <MenuItem onClick={() => handleMenuClick('https://ai.x-or.cloud/')} sx={{ mt: 1, mb: 1 }}>
                                    X-OR AI
                                </MenuItem>
                                <MenuItem onClick={() => handleMenuClick('https://portal.xorcloud.net/')} sx={{ mt: 1, mb: 1 }}>
                                    X-OR Cloud
                                </MenuItem>
                                <MenuItem onClick={() => handleMenuClick('https://stack.x-or.cloud/')} sx={{ mt: 1, mb: 1 }}>
                                    X-OR Stack
                                </MenuItem>
                                <MenuItem onClick={() => handleMenuClick('https://data.x-or.cloud/')} sx={{ mt: 1, mb: 1 }}>
                                    X-OR Data
                                </MenuItem>
                            </Menu>
                            <Link
                                color="inherit"
                                component="button"
                                onClick={handleDocsClick}
                                sx={{
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    textDecoration: 'none',
                                    position: 'relative',
                                    cursor: 'pointer',
                                    '&::before': {
                                        content: '""',
                                        position: 'absolute',
                                        left: 0,
                                        bottom: 0,
                                        width: 0,
                                        height: '2px',
                                        backgroundColor: 'primary.main',
                                        transition: 'width 1s cubic-bezier(0.25, 1, 0.5, 1)'
                                    },
                                    '&:hover::before': {
                                        width: '100%'
                                    }
                                }}
                            >
                                <Stack direction={'row'} alignItems="center" gap={1}>
                                    Docs
                                    {anchorElDocs ? <ExpandLessIcon fontSize="medium" /> : <ExpandMoreIcon fontSize="medium" />}
                                </Stack>
                            </Link>
                            <Menu anchorEl={anchorElDocs} open={Boolean(anchorElDocs)} onClose={handleCloseDocs}>
                                <MenuItem onClick={() => handleMenuClick('https://docs.x-or.cloud/san-pham/x-or-ai')} sx={{ mt: 1, mb: 1 }}>
                                    X-OR AI
                                </MenuItem>
                                <MenuItem
                                    onClick={() => handleMenuClick('https://docs.x-or.cloud/san-pham/x-or-cloud')}
                                    sx={{ mt: 1, mb: 1 }}
                                >
                                    X-OR Cloud
                                </MenuItem>
                                <MenuItem
                                    onClick={() => handleMenuClick('https://docs.x-or.cloud/san-pham/x-or-stack')}
                                    sx={{ mt: 1, mb: 1 }}
                                >
                                    X-OR Stack
                                </MenuItem>
                                <MenuItem
                                    onClick={() => handleMenuClick('https://docs.x-or.cloud/san-pham/x-or-data')}
                                    sx={{ mt: 1, mb: 1 }}
                                >
                                    X-OR Data
                                </MenuItem>
                                <MenuItem onClick={() => handleMenuClick('https://xorcloud.notion.site/')} sx={{ mt: 1, mb: 1 }}>
                                    Dataroom
                                </MenuItem>
                            </Menu>
                            <Link
                                color="inherit"
                                component="button"
                                onClick={handlePricingClick}
                                sx={{
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    textDecoration: 'none',
                                    position: 'relative',
                                    cursor: 'pointer',
                                    '&::before': {
                                        content: '""',
                                        position: 'absolute',
                                        left: 0,
                                        bottom: 0,
                                        width: 0,
                                        height: '2px',
                                        backgroundColor: 'primary.main',
                                        transition: 'width 1s cubic-bezier(0.25, 1, 0.5, 1)'
                                    },
                                    '&:hover::before': {
                                        width: '100%'
                                    }
                                }}
                            >
                                <Stack direction={'row'} alignItems="center" gap={1}>
                                    Pricing
                                    {anchorElPricing ? <ExpandLessIcon fontSize="medium" /> : <ExpandMoreIcon fontSize="medium" />}
                                </Stack>
                            </Link>
                            <Menu anchorEl={anchorElPricing} open={Boolean(anchorElPricing)} onClose={handleClosePricing}>
                                <MenuItem onClick={() => window.open('https://gpu.x-or.cloud', '_blank')} sx={{ mt: 1, mb: 1 }}>
                                    X-OR AI
                                </MenuItem>
                                <MenuItem onClick={() => handlePriceClick('/pricing/x-or-cloud')} sx={{ mt: 1, mb: 1 }}>
                                    X-OR Cloud
                                </MenuItem>

                                <MenuItem onClick={() => handlePriceClick('/pricing/x-or-stack')} sx={{ mt: 1, mb: 1 }}>
                                    X-OR Stack
                                </MenuItem>

                                <MenuItem onClick={() => handlePriceClick('/pricing/x-or-data')} sx={{ mt: 1, mb: 1 }}>
                                    X-OR Data
                                </MenuItem>
                            </Menu>
                            <Box sx={{ marginRight: '-10px' }}>
                                <ThemeModeLayout />
                            </Box>
                            {/* <Box sx={{ marginRight: '5px'}}> */}

                            {/* </Box> */}
                            <MonitorButton />
                            <ButtonComponent button={buttonGo} icon={''} variant="contained" />
                        </Stack>
                        {/* <Box sx={{ flexGrow: 1 }} /> */}
                        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                            <IconButton color="inherit" onClick={drawerToggler(true)} size="large">
                                <MenuIcon />
                            </IconButton>
                        </Box>
                        <Drawer anchor="top" open={drawerToggle} onClose={drawerToggler(false)}>
                            {drawerToggle && (
                                <Box
                                    sx={{
                                        // width: '100%',
                                        padding: '16px',
                                        overflowX: 'hidden'
                                    }}
                                    role="presentation"
                                    // onClick={drawerToggler(false)}
                                    // onKeyDown={drawerToggler(false)}
                                >
                                    <List>
                                        <ListItemButton onClick={handleOpenProductsClick}>
                                            <ListItemIcon>
                                                <ExploreIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Products" />
                                            {openProducts ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </ListItemButton>
                                        <Collapse in={openProducts}>
                                            <List component="div">
                                                <ListItemButton sx={{ pl: 4 }} onClick={() => handleMenuClick('https://ai.x-or.cloud/')}>
                                                    <ListItemIcon>
                                                        <FiberManualRecordIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText primary="X-OR AI" />
                                                </ListItemButton>
                                                <ListItemButton
                                                    sx={{ pl: 4 }}
                                                    onClick={() => handleMenuClick('https://portal.xorcloud.net/')}
                                                >
                                                    <ListItemIcon>
                                                        <FiberManualRecordIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText primary="X-OR Cloud" />
                                                </ListItemButton>
                                                <ListItemButton sx={{ pl: 4 }} onClick={() => handleMenuClick('https://stack.x-or.cloud/')}>
                                                    <ListItemIcon>
                                                        <FiberManualRecordIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText primary="X-OR Stack" />
                                                </ListItemButton>
                                                <ListItemButton sx={{ pl: 4 }} onClick={() => handleMenuClick('https://data.x-or.cloud/')}>
                                                    <ListItemIcon>
                                                        <FiberManualRecordIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText primary="X-OR Data" />
                                                </ListItemButton>
                                            </List>
                                        </Collapse>
                                        <ListItemButton onClick={handleOpenDocsClick}>
                                            <ListItemIcon>
                                                <ArticleIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Docs" />
                                            {openDocs ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </ListItemButton>
                                        <Collapse in={openDocs}>
                                            <List component="div">
                                                <ListItemButton
                                                    sx={{ pl: 4 }}
                                                    onClick={() => handleMenuClick('https://docs.x-or.cloud/san-pham/x-or-ai')}
                                                >
                                                    <ListItemIcon>
                                                        <FiberManualRecordIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText primary="X-OR AI" />
                                                </ListItemButton>
                                                <ListItemButton
                                                    sx={{ pl: 4 }}
                                                    onClick={() => handleMenuClick('https://docs.x-or.cloud/san-pham/x-or-cloud')}
                                                >
                                                    <ListItemIcon>
                                                        <FiberManualRecordIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText primary="X-OR Cloud" />
                                                </ListItemButton>
                                                <ListItemButton
                                                    sx={{ pl: 4 }}
                                                    onClick={() => handleMenuClick('https://docs.x-or.cloud/san-pham/x-or-stack')}
                                                >
                                                    <ListItemIcon>
                                                        <FiberManualRecordIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText primary="X-OR Stack" />
                                                </ListItemButton>
                                                <ListItemButton
                                                    sx={{ pl: 4 }}
                                                    onClick={() => handleMenuClick('https://docs.x-or.cloud/san-pham/x-or-data')}
                                                >
                                                    <ListItemIcon>
                                                        <FiberManualRecordIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText primary="X-OR Data" />
                                                </ListItemButton>
                                                <ListItemButton
                                                    sx={{ pl: 4 }}
                                                    onClick={() => handleMenuClick('https://xorcloud.notion.site/')}
                                                >
                                                    <ListItemIcon>
                                                        <FiberManualRecordIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Dataroom" />
                                                </ListItemButton>
                                            </List>
                                        </Collapse>
                                        <ListItemButton onClick={handleOpenPricing}>
                                            <ListItemIcon>
                                                <LocalOfferIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Pricing" />
                                            {openPricing ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </ListItemButton>
                                        <Collapse in={openPricing}>
                                            <List component="div">
                                                <ListItemButton
                                                    sx={{ pl: 4 }}
                                                    onClick={() => window.open('https://gpu.x-or.cloud', '_blank')}
                                                >
                                                    <ListItemIcon>
                                                        <FiberManualRecordIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText primary="X-OR AI" />
                                                </ListItemButton>
                                                <ListItemButton sx={{ pl: 4 }} onClick={() => handlePriceClick('/pricing/x-or-cloud')}>
                                                    <ListItemIcon>
                                                        <FiberManualRecordIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText primary="X-OR Cloud" />
                                                </ListItemButton>
                                                <ListItemButton sx={{ pl: 4 }} onClick={() => handlePriceClick('/pricing/x-or-stack')}>
                                                    <ListItemIcon>
                                                        <FiberManualRecordIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText primary="X-OR Stack" />
                                                </ListItemButton>
                                                <ListItemButton sx={{ pl: 4 }} onClick={() => handlePriceClick('/pricing/x-or-data')}>
                                                    <ListItemIcon>
                                                        <FiberManualRecordIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText primary="X-OR Data" />
                                                </ListItemButton>
                                                {/* <ListItemButton sx={{ pl: 4 }} onClick={() => handlePriceClick('/pricing/x-or-data')}>
                                                    <ListItemIcon>
                                                        <FiberManualRecordIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Solutions" />
                                                </ListItemButton> */}
                                            </List>
                                        </Collapse>
                                        <Stack direction="column" gap={2} sx={{ padding: 2 }}>
                                            <Box sx={{ px: 1 }}>
                                                <ThemeModeLayout />
                                            </Box>
                                            <MonitorButton />
                                            <ButtonComponent button={buttonGo} icon={''} variant="contained" />
                                        </Stack>
                                    </List>
                                </Box>
                            )}
                        </Drawer>
                        {/* </Box> */}
                    </Toolbar>
                </Container>
            </MuiAppBar>
        </ElevationScroll>
    );
};

export default AppBar;
